.rgba-gradient {
  background-color: #eee;
}


/* styles.css */

/* Sol taraf için stil */
.left-image-section {
  position: relative;
  padding-left: 150px; /* Resmin üzerine yazı yazmamak için boşluk ekleyin */
}

/* styles.css */

/* Sağdaki resmi (homeImage) büyütmek için */
.home-image {
  width: 500px; /* Genişliği artır */
  height: auto; /* Yüksekliği otomatik ayarla (orantılı olarak) */
}

.left-image {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%); /* Resmi dikeyde ortala */
  width: 100px; /* Resmin genişliği */
  height: auto; /* Resmin yüksekliği otomatik ayarlanır */
  z-index: 1; /* Resmi metnin önüne getir */
}

/* Sağ taraf için stil */
.img-fluid {
  max-width: 100%;
  height: auto;
}



.header {
  height: 100vh;
  color: #fff;
  position: relative;
  background-image: url('../../../assets/images/fenerbahce.png');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 850px auto; /* Resmin genişliğini artır (örneğin 400px) */
  z-index: 1;

}

.dev-img {
  margin-top: 90px; /* Resmi 80px aşağı kaydırır */
  animation: move 2.5s ease-in-out infinite;
}

@keyframes move {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(40px);
    transform: translateX(20px);
  }
}
